import HCaptcha from "@hcaptcha/react-hcaptcha";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import NoPicture from "../../assets/no-picture.png";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

import { Trans, useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";

const noteByTextSize = {
  80: { title: "Weak", titleFr: "Passable", className: "bad" },
  160: { title: "Average", titleFr: "Moyen", className: "not_bad" },
  240: { title: "Good", titleFr: "Bien", className: "good" },
  320: { title: "Very Good", titleFr: "Très bien", className: "very_good" },
  400: { title: "Excellent", titleFr: "Excellent", className: "excellent" },
};

// add line here
const noteByTextSizeFr = {
  80: { title: "Passable", className: "bad" },
  160: { title: "Moyen", className: "not_bad" },
  240: { title: "Bien", className: "good" },
  320: { title: "Très Bien", className: "very_good" },
  400: { title: "Excellent", className: "excellent" },
};

const textSizeByNote = {
  BAD: 80,
  NOT_BAD: 160,
  GOOD: 240,
  VERY_GOOD: 320,
  EXCELLENT: 400,
};

function MerchantReviewForm({ textAreaId }) {
  const { t, i18n } = useTranslation();

  const [text, setText] = useState("");
  const [note, setNote] = useState(noteByTextSize[textSizeByNote.BAD]);
  const [noteFr, setNoteFr] = useState(noteByTextSizeFr[textSizeByNote.BAD]);
  const [previousClassName, setPreviousClassName] = useState(
    noteByTextSize[textSizeByNote.BAD].className
  );
  const [noteReview, setNoteReview] = useState(null);
  const [selectedOption, setSelectedOption] = useState("1");
  const [ratingReview, setRatingReview] = useState(1);
  const [invitation, setInvitation] = useState(null);
  const [date, setDate] = useState(new Date());
  const [token, setToken] = useState(null);
  const [noteTitles, setNoteTitles] = useState([
    "Very Bad",
    "Bad",
    "Average",
    "Good",
    "Excellent",
  ]);
  const [noteTitlesFr, setNoteTitlesFr] = useState([
    "Très mauvais",
    "Mauvais",
    "Moyen",
    "Bien",
    "Excellent",
  ]);
  const captchaRef = useRef(null);

  const [a, setA] = useState("");
  const [jobId, setJobId] = useState("");
  const [userId, setUserId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [website, setWebsite] = useState("");
  const endpoint = window.location.href.split("/")[4];

  const navigate = useNavigate();
  // const { addToast } = useToasts()

  useEffect(() => {
    const invitationUrl = window.location.href
      .replace("https://", "")
      .replace("http://", "");

    axios
      .get(
        "/data/invitationdata/?invitationUrl=" +
          encodeURIComponent(invitationUrl)
      )
      .then((res) => {
        if (res == null) navigate("/404", { replace: true });

        axios
          .get(
            "/data/transaction-get/" +
              res.data.invitation_url_complete
                .split("?")[1]
                .split("&")[0]
                .split("=")[1]
          )
          .then((response) => {
            if (response.data.transaction_state == "completed")
              navigate("/transaction_completed/" + endpoint, { replace: true });
          })
          .catch((err) => {
            // console.log(err);
          });

        // console.log(res);
        setInvitation(res);
        setA(res.data.invitation_url_complete);
        setJobId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[0]
            .split("=")[1]
        );
        setUserId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[1]
            .split("=")[1]
        );
        setWebsite(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[2]
            .split("=")[1]
        );
        setOrderId(
          res.data.invitation_url_complete
            .split("?")[1]
            .split("&")[3]
            .split("=")[1]
        );
        setMerchantId(res.data.profile_id);
      })
      .catch((err) => {
        // console.log(err);
      });

    // console.log(invitationUrl);
  }, []);

  useEffect(() => {
    const textArea = document.getElementById("content");
    if (!textArea) {
      // console.log(textArea);
      throw new Error(
        `The textarea element with id ${textAreaId} does not exist`
      );
    }

    const ReviewNote = document.getElementById("noteReview");

    setNoteReview(ReviewNote);

    textArea.addEventListener("input", handleInput);
    return () => textArea.removeEventListener("input", handleInput);
  }, []);

  const handleInput = (e) => {
    const text = e.target.value.trim();
    setText(text);

    const textLength = text.length;
    const currentNote = getNoteByTextSize(textLength);
    setNote(currentNote);

    if (previousClassName === currentNote.className) return;

    noteReview.classList.remove(previousClassName);
    noteReview.classList.add(currentNote.className);
    setPreviousClassName(currentNote.className);
  };

  const getNoteByTextSize = (textLength) => {
    if (textLength < textSizeByNote.BAD) {
      return noteByTextSize[textSizeByNote.BAD];
    }

    for (let key in textSizeByNote) {
      if (textLength < textSizeByNote[key]) {
        return noteByTextSize[textSizeByNote[key]];
      }
    }

    return noteByTextSize[textSizeByNote.EXCELLENT];
  };
  // Reviews stars

  const merchandRef = useRef(null);
  const reviewValueRef = useRef(null);
  const reviewValueTextRef = useRef(null);
  useEffect(() => {
    const merchand = merchandRef.current;
    const stars = merchand.querySelectorAll(".star-review");
    const reviewValue = reviewValueRef.current;
    const reviewValueText = reviewValueTextRef.current;
    const starsArray = Array.from(stars);
    starsArray.forEach((star, index) => {
      fillStarsWithSelectedValue(index + 1);
      handleClickOnReview(star);
      handleHoverOnReview(star);
    });

    function handleHoverOnReview(star) {
      star.addEventListener("mouseover", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
      });

      star.addEventListener("mouseout", () => {
        const rating = star?.dataset?.index;
        emptyStars(rating);
        fillStarsWithSelectedValue(merchand.dataset.selected);
      });
    }

    function handleClickOnReview(star) {
      star.addEventListener("click", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
        merchand.dataset.selected = rating;

        setRatingReview(rating);

        reviewValue.innerHTML = rating;
        if (rating === 1)
          reviewValueText.innerHTML = " <strong> star: </strong>Very bad";
        if (rating === 2)
          reviewValueText.innerHTML = " <strong> stars: </strong>Bad";
        if (rating === 3)
          reviewValueText.innerHTML = " <strong> stars: </strong>Average";
        if (rating === 4)
          reviewValueText.innerHTML = " <strong> stars: </strong>Good";
        if (rating === 5)
          reviewValueText.innerHTML = " <strong> stars: </strong>Excellent";
      });
    }

    function fillStarsWithSelectedValue(index) {
      if (Number(index) !== Number(merchand.dataset.selected)) return;
      fillStars(Number(merchand.dataset.selected));
      emptyStars(Number(merchand.dataset.selected));
    }

    function emptyStars(rating) {
      const afterStar = starsArray.filter(
        (s) => Number(s.dataset.index) > Number(rating)
      );
      afterStar.forEach((s) => s.classList.remove("star-filled"));
    }

    function fillStars(rating) {
      const beforeStar = starsArray.filter(
        (s) => Number(s.dataset.index) <= Number(rating)
      );

      beforeStar.forEach((s) => s.classList.add("star-filled"));
    }
  }, []);

  // Inputs

  // Method using useRef

  const title = useRef(null);
  const content = useRef(null);
  const experienceDate = useRef(null);
  const [reviewObj, setReviewObj] = useState({
    title: "",
    content: "",
    rating: "0",
    experienceDate: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [values, setValues] = useState({
    title: "",
    content: "",
    experienceDate: date.toISOString().slice(0, 10),
  });

  const axios = useAxios();
  //comportement

  const handleSubmit = (e) => {
    e.preventDefault();

    title.current.value = "";
    content.current.value = "";
    experienceDate.current.value = "";
    setValues({ title: "", content: "", experienceDate: "" });
    setReviewObj({
      title: values.title,
      content: values.content,
      rating: ratingReview,
      experienceDate: values.experienceDate,
    });

    const review = {
      title: values.title,
      content: text,
      rating: ratingReview,
      experienceDate: values.experienceDate,
      order_id: orderId,
      job_id: jobId,
      merchant_id: merchantId,
      user_id: userId,
      lang_id: 22,
    };
    // console.log(review);
    if (token) {
      axios
        .post("/data/merchantreview-invitation", review, false)
        .then((res) => {
          // console.log(res);

          toast.success("Your review have been saved successfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          /************************************************  Update transacyion to completed */
          axios
            .put(
              "/data/transaction-update/" + jobId,
              {},
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              // console.log(err);
            });

          axios
            .put(
              "/merchant_profiles/" + website,
              {},
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {});
          /***************** End */

          navigate("/preview/" + endpoint, { replace: true });
        })
        .catch((err) => {
          // console.log(err);
          //addToast(err.message, { appearance: 'error' })
        });
    } else {
      toast.error("hcaptcha must be verified", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const onLoad = () => {
    captchaRef.current.execute();
  };

  // useEffect(() => {
  //   if (token) console.log(`hCaptcha Token: ${token}`);
  // }, [token]);

  return (
    <>
      <section className="py-5 form merchant">
        <form id="review_form" onSubmit={handleSubmit} action="">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-11 col-lg-8 pt-3 mx-auto text-center">
                <h3>
                  {t("merchantformTitle")}
                  <br />
                  <span>{website}</span>
                </h3>
                <p>{t("merchantformDescr")}</p>
              </div>
              <div className="col-11 col-lg-8 py-3 mx-auto position-relative">
                {/* {i18n.language == "fr-FR" ? ( */}

                {/* ) : ( */}
                <ul className="step-reviews nav text-center">
                  <li className="nav-item w-33 step-1 active ">
                    <span className="badge rounded-circle">1</span>
                    <p className="mb-0">{t("arianeMerchant")}</p>
                  </li>
                  <li className="nav-item w-33 step-2 ">
                    <span className="badge rounded-circle">2</span>
                    <p className="mb-0" aria-current="page">
                      {t("arianeProduct")}
                    </p>
                  </li>
                  <li className="nav-item w-33 step-3 ">
                    <span className="badge rounded-circle">3</span>
                    <p className="mb-0">{t("arianeReview")}</p>
                  </li>
                </ul>
                {/* )} */}
                <div id="line">
                  <div id="line-progress"></div>
                </div>
              </div>
              <div className="col-11 col-lg-8 py-4 px-lg-4 mx-auto bg-light rounded-5">
                <div className="form__header">
                  <div className="row d-flex align-items-center">
                    <div className="col-8 col-md-4 col-xl-3 d-flex rounded-4 border bg-white visuel-mark logo mx-auto">
                      <img
                        className="align-self-center m-0"
                        src={NoPicture}
                        alt="Merchant name"
                      />
                    </div>
                    <div className="col-12 col-md-8 col-xl-9">
                      <div className="form__header__note">
                        <p className="lead">{t("merchantformActionTitle")}</p>
                        <div
                          className="form__star mb-3 d-flex justify-content-start"
                          id="review"
                          data-review="merchand"
                          ref={merchandRef}
                          data-selected="1"
                        >
                          <div
                            className="star-review star-default star-empty star-filled"
                            data-index="1"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="2"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="3"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="4"
                          ></div>
                          <div
                            className="star-review star-default star-empty"
                            data-index="5"
                          ></div>
                        </div>
                        <p>
                          <span id="review-value" ref={reviewValueRef}>
                            {ratingReview}
                          </span>{" "}
                          <span ref={reviewValueTextRef}>
                            {" "}
                            {/* <strong> stars: </strong>
                            {noteTitles[ratingReview - 1]}{" "} */}
                            {ratingReview == 1 ? (
                              <Trans
                                i18nKey="AddreviewMerchant1star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 2 ? (
                              <Trans
                                i18nKey="AddreviewMerchant2star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 3 ? (
                              <Trans
                                i18nKey="AddreviewMerchant3star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 4 ? (
                              <Trans
                                i18nKey="AddreviewMerchant4star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : ratingReview == 5 ? (
                              <Trans
                                i18nKey="AddreviewMerchant5star"
                                components={{
                                  1: <strong></strong>,
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="form__content">
                  <p className="lead mt-3 mb-0">
                    {t("merchantFormWriteTitle")}
                  </p>
                  <input
                    className="form-control"
                    type="text"
                    id="title"
                    name="title"
                    ref={title}
                    value={values.title}
                    onChange={handleChange}
                    required=""
                  />
                  <p className="lead mb-0">{t("merchantFormWriteContent")}</p>
                  <p id="note_review" className="">
                    {t("AddreviewMerchantContentScore")}{" "}
                    <span id="noteReview" className={note.className}>
                      {note.title}
                    </span>{" "}
                  </p>
                  <textarea
                    className="form-control"
                    name="content"
                    id="content"
                    rows="10"
                    placeholder={t("merchantFormPlaceholderContent")}
                    required=""
                    ref={content}
                    onChange={handleInput}
                  ></textarea>
                  <p className="lead mt-3 mb-0">
                    {t("merchantFormExperienceDate")}
                  </p>
                  <input
                    className="form-control"
                    type="date"
                    max={date.toISOString().slice(0, 10)}
                    data-date-inline-picker="true"
                    name="experienceDate"
                    id="date"
                    required=""
                    ref={experienceDate}
                    value={values.experienceDate}
                    onChange={handleChange}
                  />
                </div>
                <div className="form__footer">
                  <HCaptcha
                    sitekey="1be3a25e-95cb-441d-a951-f140b9e09428"
                    explicit={true}
                    onLoad={onLoad}
                    onVerify={setToken}
                    ref={captchaRef}
                  />

                  <span id="error"></span>

                  <div className="d-grid gap-2">
                    <button
                      type="submit"
                      className="btn-block btn btn-primary btn-lg text-uppercase text-right"
                      id="btn"
                      disabled={token ? false : true}
                    >
                      {t("merchantFormPublish")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default MerchantReviewForm;
