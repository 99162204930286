import React, { useEffect, useState } from "react";
import flagFr from "../../assets/flag-fr.png";
import flagGb from "../../assets/flag-gb.png";
import flagIt from "../../assets/flag-it.png";
import Logo from "../../assets/logo.png";
import "../../styles/flaticon_veritatrust.css";
//import '../../styles/owl.carousel.css'
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import level0 from "../../assets/level0.jpg";
import level1 from "../../assets/level1.jpg";
import level2 from "../../assets/level2.jpg";
import level3 from "../../assets/level3.jpg";
import level4 from "../../assets/level4.jpg";
import "../../styles/bootstrap.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
import Nav from "../Nav/index";
import SearchBar from "../searchBar";

function Header() {
  const axios = useAxios();
  const [isActive, setActive] = useState(false);
  const [typeheader, setTypeheader] = useState(null);
  const [userid, setUserid] = useState(0);
  const [profiledata, setProfiledata] = useState(null);
  const [levelUser, setLevelUser] = useState(0);
  let [imgUser, setImgUser] = useState(level0);
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let socket;
  const [notif, setNotif] = useState(false);

  const { lang } = useParams();
  const [lng, setLng] = useState(lang);
  const [signinLink, setSigninLink] = useState(lang + "/signin");
  const [signupLink, setSignupLink] = useState(lang + "/signup");
  const [notifData, setNotifData] = useState([]);

  const [selectedValueLang, setSelectedValueLang] = useState("en");
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(lang);
    setLng(lang);
    setSigninLink(lang + "/signin");
    setSignupLink(lang + "/signup");
    const Url = window.location.href;
    setTypeheader(Url.split("/")[3]);
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      setUserid(response?.data["id"]);
      setProfiledata(response?.data["profile_url"]);
      setLevelUser(response?.data["level_account"]);
      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        setUserid(retrievedObject["id"]);
        setProfiledata(retrievedObject["profile_url"]);
        setLevelUser(retrievedObject["level_account"]);
      }
      //  console.log(response.data["level_account"]);

      switch (response?.data["level_account"]) {
        case 0:
          setImgUser(level0);
          break;
        case 1:
          setImgUser(level1);
          break;
        case 2:
          setImgUser(level2);
          break;
        case 3:
          setImgUser(level3);
          break;

        case 4:
          setImgUser(level4);
          break;
      }
      socket = io("https://api.veritatrust.com"); // Remplacez par l'URL correcte

      socket.on("connect", () => {
        console.log("WebSocket Client Connected successfully lorem ipsum ");
        console.log("Socket ID:", socket.id);
        socket.emit("register", response?.data["id"]);
        // Écouter l'événement "notification" pour recevoir les notifications
      });

      socket.on("notification", (notification) => {
        setNotifData((prevNotifications) => [
          notification[0],
          ...prevNotifications,
        ]);
        setNotif(true);
        console.log(notification);
      });

      const responseNotif = await axios.get(
        "/notification/" + response?.data?.id
      );

      if (responseNotif) {
        // Filter the data based on the notiftype
        const filteredData = notifData.filter((item) => item.isViewed == "0");
      }
    };
    fetchReviews();
    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("notification");
        socket.disconnect();
      }
    };
  }, []);

  const HandleChangeLanguage = (event) => {
    const selectedLanguage =
      event.currentTarget.getAttribute("target-data-lang");
    i18n.changeLanguage(selectedLanguage);
    setSelectedValueLang(event.currentTarget.getAttribute("target-data-lang"));

    const currentUrl = window.location.pathname;

    navigate(`/${selectedLanguage}/${currentUrl.substring(4)}`);
    window.localStorage.setItem("langPage", selectedLanguage);
  };

  // Comportements
  function handleClick() {
    setActive(!isActive);
  }

  return typeheader !== "preview" &&
    typeheader !== "mreview" &&
    typeheader !== "review-valided" &&
    typeheader !== "review-validated" &&
    typeheader !== "confirmaaccount" &&
    window.location.href.split("/")[4] !== "login-admin" &&
    window.location.href.split("/")[4] !== "admin-dashboard" &&
    window.location.href.split("/")[4] !== "review-moderation" &&
    window.location.href.split("/")[4] !== "admin-allreviews" &&
    window.location.href.split("/")[4] !== "admin-products" &&
    window.location.href.split("/")[4] !== "admin-webshop" &&
    window.location.href.split("/")[4] !== "admin-brands" &&
    window.location.href.split("/")[4] !== "admin-support" &&
    window.location.href.split("/")[4] !== "admin-merchantusers" ? (
    <>
      <nav className="navbar sticky-top navbar-white bg-white navbar-dark navbar-expand-lg shadow-sm">
        <div className="container" id="container-menu">
          {" "}
          <Link className="navbar-brand" to={`/${t("lang")}`}>
            <img src={Logo} alt="Veritatrust" width="170" />
          </Link>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#search-menu"
            aria-controls="search-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="dropdown me-1 d-md-none">
            <button
              type="button"
              class="btn btn-white dropdown-toggle lang"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-offset="10,20"
            >
              {t("lang") === "en" && (
                <img src={flagGb} alt="en" width="24" height="16" />
              )}
              {t("lang") === "fr" && (
                <img src={flagFr} alt="fr" width="24" height="16" />
              )}
              {t("lang") === "it" && (
                <img src={flagIt} alt="it" width="24" height="16" />
              )}
            </button>

            <ul class="dropdown-menu">
              <li>
                <button
                  class="dropdown-item"
                  onClick={HandleChangeLanguage}
                  target-data-lang="en"
                >
                  <img src={flagGb} alt="en" width="64" height="42" />
                </button>
              </li>
              <li>
                <button
                  class="dropdown-item"
                  onClick={HandleChangeLanguage}
                  target-data-lang="fr"
                >
                  <img src={flagFr} alt="fr" width="64" height="42" />
                </button>
              </li>
              <li>
                <button
                  class="dropdown-item"
                  onClick={HandleChangeLanguage}
                  target-data-lang="it"
                >
                  <img src={flagIt} alt="it" width="64" height="42" />
                </button>
              </li>
            </ul>
          </div>
          <a
            className="avatar-link nav-link align-self-center order-3 order-lg-5 position-relative"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#account"
            aria-controls="account"
            onClick={() => setActive(!isActive)}
          >
            {notif == true && (
              <span class="notification position-absolute translate-middle p-2 bg-danger rounded-circle">
                <span class="visually-hidden">New alerts</span>
              </span>
            )}
            {userid > 0 ? (
              <img
                className="user_avatar"
                src={profiledata || imgUser}
                alt="user"
                width="40"
              />
            ) : (
              <i className="flaticon-user-1"></i>
            )}
          </a>
          <div className="collapse navbar-collapse order-3" id="search-menu">
            <SearchBar propsLang={lang} />

            <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
              <li className="nav-item">
                <Link
                  to={
                    userid > 0
                      ? `/${window.localStorage.getItem("langPage")}/add-review`
                      : `/${t("lang")}/signup`
                  }
                  className="nav-link  align-self-center"
                  aria-current="page"
                >
                  {" "}
                  {userid > 0 ? t("AddreviewTitle") : t("HeaderSignup")}
                </Link>
              </li>
              {userid > 0 ? (
                <></>
              ) : (
                <li className="nav-item">
                  <Link
                    to={`/${t("lang")}/signin`}
                    className="nav-link  align-self-center"
                    aria-current="page"
                  >
                    {t("HeaderSignin")}
                  </Link>
                </li>
              )}
            </ul>
            <div class="dropdown me-1 d-none d-md-block">
              <button
                type="button"
                class="btn btn-white dropdown-toggle lang"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-offset="10,20"
              >
                {t("lang") === "en" && (
                  <img src={flagGb} alt="en" width="24" height="16" />
                )}
                {t("lang") === "fr" && (
                  <img src={flagFr} alt="fr" width="24" height="16" />
                )}
                {t("lang") === "it" && (
                  <img src={flagIt} alt="it" width="24" height="16" />
                )}
              </button>

              <ul class="dropdown-menu">
                <li>
                  <button
                    class="dropdown-item"
                    onClick={HandleChangeLanguage}
                    target-data-lang="en"
                  >
                    <img src={flagGb} alt="en" width="64" height="42" />
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    onClick={HandleChangeLanguage}
                    target-data-lang="fr"
                  >
                    <img src={flagFr} alt="fr" width="64" height="42" />
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    onClick={HandleChangeLanguage}
                    target-data-lang="it"
                  >
                    <img src={flagIt} alt="it" width="64" height="42" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      {<Nav isActive={isActive} handleClick={handleClick} />}
    </>
  ) : (
    <>
      <nav className="navbar sticky-top navbar-white bg-white navbar-dark navbar-expand-lg shadow-sm">
        <div className="container" id="container-menu">
          <img src={Logo} alt="Veritatrust" width="170" />
        </div>
      </nav>
    </>
  );
}

export default Header;
